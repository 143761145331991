/* tslint:disable */
/* eslint-disable */
/**
 * API for the AwsGsaasGSOCBackendLambda
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2018-05-10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface EchoRequestContent
 */
export interface EchoRequestContent {
    /**
     * 
     * @type {string}
     * @memberof EchoRequestContent
     */
    string?: string;
}
/**
 * 
 * @export
 * @interface EchoResponseContent
 */
export interface EchoResponseContent {
    /**
     * 
     * @type {string}
     * @memberof EchoResponseContent
     */
    string?: string;
}
/**
 * GetCredentialsResponse
 * @export
 * @interface GetCredentialsResponseContent
 */
export interface GetCredentialsResponseContent {
    /**
     * RoleName
     * @type {string}
     * @memberof GetCredentialsResponseContent
     */
    roleName?: string;
}
/**
 * 
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
    /**
     * 
     * @type {string}
     * @memberof InvalidInputExceptionResponseContent
     */
    message?: string;
}
/**
 * ListRolesResponse
 * @export
 * @interface ListRolesResponseContent
 */
export interface ListRolesResponseContent {
    /**
     * roleList
     * @type {Array<RoleSummary>}
     * @memberof ListRolesResponseContent
     */
    roleList?: Array<RoleSummary>;
}
/**
 * Role Summary
 * @export
 * @interface RoleSummary
 */
export interface RoleSummary {
    /**
     * Unique ID and name of Role
     * @type {string}
     * @memberof RoleSummary
     */
    roleName?: string;
    /**
     * Is the user authorized to assume the role
     * @type {boolean}
     * @memberof RoleSummary
     */
    isAuthorized?: boolean | null;
    /**
     * The BindleLock associated with the role
     * @type {string}
     * @memberof RoleSummary
     */
    bindleLock?: string;
    /**
     * The permission level associated with the role
     * @type {string}
     * @memberof RoleSummary
     */
    permissionLevel?: string;
    /**
     * Description of the role
     * @type {string}
     * @memberof RoleSummary
     */
    description?: string;
    /**
     * RoleArn
     * @type {string}
     * @memberof RoleSummary
     */
    roleArn?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Echo operation that receive input from body, query-string, and path-parameters.
         * @param {EchoRequestContent} [echoRequestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        echo: async (echoRequestContent?: EchoRequestContent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof echoRequestContent !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(echoRequestContent !== undefined ? echoRequestContent : {})
                : (echoRequestContent || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * GetCredentials from an authorized role
         * @param {string} roleName RoleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials: async (roleName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleName' is not null or undefined
            if (roleName === null || roleName === undefined) {
                throw new RequiredError('roleName','Required parameter roleName was null or undefined when calling getCredentials.');
            }
            const localVarPath = `/credentials/{roleName}`
                .replace(`{${"roleName"}}`, encodeURIComponent(String(roleName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List of IAM Roles available to the WebFrontEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Echo operation that receive input from body, query-string, and path-parameters.
         * @param {EchoRequestContent} [echoRequestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async echo(echoRequestContent?: EchoRequestContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EchoResponseContent>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).echo(echoRequestContent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * GetCredentials from an authorized role
         * @param {string} roleName RoleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredentials(roleName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCredentialsResponseContent>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCredentials(roleName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of IAM Roles available to the WebFrontEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRolesResponseContent>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).listRoles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Echo operation that receive input from body, query-string, and path-parameters.
         * @param {EchoRequestContent} [echoRequestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        echo(echoRequestContent?: EchoRequestContent, options?: any): AxiosPromise<EchoResponseContent> {
            return DefaultApiFp(configuration).echo(echoRequestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * GetCredentials from an authorized role
         * @param {string} roleName RoleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials(roleName: string, options?: any): AxiosPromise<GetCredentialsResponseContent> {
            return DefaultApiFp(configuration).getCredentials(roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * List of IAM Roles available to the WebFrontEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any): AxiosPromise<ListRolesResponseContent> {
            return DefaultApiFp(configuration).listRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Echo operation that receive input from body, query-string, and path-parameters.
     * @param {EchoRequestContent} [echoRequestContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public echo(echoRequestContent?: EchoRequestContent, options?: any) {
        return DefaultApiFp(this.configuration).echo(echoRequestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GetCredentials from an authorized role
     * @param {string} roleName RoleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCredentials(roleName: string, options?: any) {
        return DefaultApiFp(this.configuration).getCredentials(roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of IAM Roles available to the WebFrontEnd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRoles(options?: any) {
        return DefaultApiFp(this.configuration).listRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


