import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { RoleSummary } from '../../gsoc-api/generated-src';
import { ReactNode } from 'react';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<RoleSummary>[] = [
  {
    id: 'roleName',
    header: 'roleName',
    cell: item => item.roleName,
    minWidth: '180px'
  },
  {
    id: 'permmisionLevel',
    cell: item => item.permissionLevel,
    header: 'permissionLevel',
    minWidth: '160px'
  },
  {
    id: 'isAuthorized',
    header: 'isAuthorized',
    cell: item => item.isAuthorized,
    minWidth: '100px'
  },
  {
    id: 'roleArn',
    header: 'roleArn',
    cell: item => item.roleArn,
    minWidth: '300px',
  },
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
